<template>
  <div class="login-container" v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" class="login-form">
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email" @keyup.enter.native="onLogin"/>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="form.password" @keyup.enter.native="onLogin"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onLogin">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import md5 from 'js-md5';
import {eventBus} from '@/eventBus'
import {post} from '@/api.js';

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      loading: false,
      salt: 'TaocoWebInvestBlog',
      rules: {
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, message: '密码长度至少为6个字符', trigger: 'blur'}
        ],
      },
    }
  },
  created() {
    eventBus.$emit('loginExpire')
  },
  methods: {
    onLogin() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const password = md5(this.salt + this.form.password)
          post('/user/login', {email: this.form.email, password: password}).then(res => {
            eventBus.$emit('loginSuccess')
            localStorage.setItem('token', res.data.access_token)
            const post = localStorage.getItem('postRoute')
            const postRouteParams = localStorage.getItem('postRouteParams')
            const curr = localStorage.getItem('preRoute')
            if ((post == null && curr == null) || curr === '/register') {
              this.$router.push({path: '/'})
            } else {
              if (post != null) {
                let params = null
                if (postRouteParams != null) {
                  params = JSON.parse(postRouteParams)
                }
                // 传递param只能是name
                this.$router.push({name: post, params: params});
                localStorage.removeItem('postRoute')
                localStorage.removeItem('postRouteParams')
              } else {
                this.$router.push({path: curr});
                localStorage.removeItem('preRoute')
              }
            }
          }).finally(() => {
            this.loading = false
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.login-form {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>