<template>
  <el-dialog v-if="visible" :title="form.user_id ? '修改' : '新增'" :visible.sync="visible" modal
             :close-on-click-modal="false"
             width="30%" :before-close="close">
    <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
      <!-- <el-form-item label="日期" prop="date">
          <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="form.date" type="date"
              placeholder="选择日期">
          </el-date-picker>
      </el-form-item> -->
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
  </el-dialog>
</template>

<script>
import {post} from '../../api'

export default {
  data() {
    return {
      visible: false,
      form: {
        // date: '',
        username: '',
        email: ''
      },
      rules: {
        // date: [
        //     { required: true, message: "请选择日期", trigger: "change" },
        // ],
        username: [
          {required: true, message: "请输入姓名", trigger: "blur"},
          {min: 3, max: 10, message: "长度在 3 到 10 个字符", trigger: "blur"},
        ],
        email: [
          {required: true, message: "请输入邮箱", trigger: "blur"},
          {type: 'email', message: "请输入正确的邮箱格式", trigger: ['blur', 'change']},
        ],
      },
    };
  },
  methods: {
    open(row) {
      this.visible = true;
      // 有id则是编辑 否则则是新增
      if (row && row.user_id) {
        this.form = JSON.parse(JSON.stringify(row));
      }
    },
    close() {
      this.visible = false;
      this.form = {};
      // 移除校验结果并重置数据
      this.$refs.form.resetFields()
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          post("user/add-or-update", this.form).then(res => {
            if (res.success) {
              this.$message.success('保存成功');
              this.$parent.getTableData();
              this.close();
            }
          })
        } else {
          return false;
        }
      });
    },
  },
};
</script>