import {get, post, getRestful} from '@/api';

// 模型提供商相关API
export const modelProviderApi = {
    // 获取所有模型提供商
    getProviders() {
        return get('/model_provider/providers');
    },

    // 获取单个模型提供商
    getProvider(providerId) {
        return getRestful('/model_provider/provider', providerId);
    },

    // 保存模型提供商（创建/编辑）
    saveProvider(data) {
        return post('/model_provider/provider/save', data);
    },

    // 删除模型提供商
    deleteProvider(providerId) {
        return getRestful('/model_provider/provider/delete', providerId);
    },

    // 获取提供商的所有模型
    getProviderModels(providerId) {
        return getRestful('/model_provider/provider/models', providerId);
    },

    // 获取所有模型
    getAllModels() {
        return get('/model_provider/models');
    },

    // 获取单个模型
    getModel(modelId) {
        return getRestful('/model_provider/model', modelId);
    },

    // 保存模型（创建/编辑）
    saveModel(data) {
        return post('/model_provider/model/save', data);
    },

    // 删除模型
    deleteModel(id) {
        return getRestful('/model_provider/model/delete', id);
    }
} 