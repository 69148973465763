<template>
  <div class="model-manager">
    <el-row :gutter="20">
      <el-col :span="5">
        <model-provider-list
            @select-provider="handleProviderSelect"/>
      </el-col>
      <el-col :span="19">
        <model-list :provider="selectedProvider"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ModelProviderList from './ModelProviderList.vue';
import ModelList from './ModelList.vue';

export default {
  name: 'ModelManager',
  components: {
    ModelProviderList,
    ModelList
  },
  data() {
    return {
      selectedProvider: null
    };
  },
  methods: {
    handleProviderSelect(provider) {
      this.selectedProvider = provider; // 更新所选厂商
    }
  }
};
</script>

<style scoped>
.model-manager {
  min-height: 100%;
  height: calc(100vh - 120px);
}
</style>