<template>
  <div class="provider-list">
    <el-menu
        :default-active="activeProvider"
        @select="handleSelect"
        class="provider-menu"
    >
      <el-menu-item
          v-for="provider in providers"
          :key="provider.id"
          :index="provider.id.toString()"
      >
        <span>{{ provider.name }}</span>
        <el-button
            type="text"
            class="setting-btn"
            @click.stop="showSettings(provider)"
        >
          <i class="el-icon-setting"></i>
        </el-button>
      </el-menu-item>
    </el-menu>

    <!-- 添加新提供商的按钮 -->
    <el-button
        class="add-provider"
        type="text"
        @click="showAddProviderDialog"
    >
      <i class="el-icon-plus"></i>
      <span>添加新的模型提供商</span>
    </el-button>

    <provider-settings
        v-if="settingsVisible"
        :visible.sync="settingsVisible"
        :providers="providers"
        :current-provider="currentProvider"
        @update="handleProvidersUpdate"
    />

    <!-- 新增提供商弹框 -->
    <el-dialog
        title="添加新的模型提供商"
        :visible.sync="addProviderVisible"
        width="400px"
    >
      <el-form :model="newProvider" label-width="100px">
        <el-form-item label="提供商名称" :rules="[{ required: true, message: '请输入提供商名称' }]">
          <el-input v-model="newProvider.name" placeholder="请输入提供商名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addProviderVisible = false">取消</el-button>
        <el-button type="primary" @click="handleAddProvider">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ProviderSettings from './ProviderSettings.vue';
import {modelProviderApi} from '@/api/model_provider';

export default {
  name: 'ModelProviderList',
  components: {
    ProviderSettings
  },
  data() {
    return {
      providers: [],
      activeProvider: null,
      settingsVisible: false,
      currentProvider: null,
      addProviderVisible: false,
      newProvider: {
        name: ''
      }
    };
  },
  async created() {
    await this.fetchProviders();
  },
  methods: {
    async fetchProviders() {
      try {
        const res = await modelProviderApi.getProviders();
        this.providers = res.data
        if (this.providers.length > 0 && !this.activeProvider) {
          this.handleSelect(this.providers[0].id);
          this.$emit('select-provider', this.providers[0]);
        }
      } catch (error) {
        this.$message.error('获取提供商列表失败：' + (error.message || '未知错误'));
      }
    },
    handleSelect(index) {
      this.activeProvider = index.toString();
      this.$emit('select-provider', this.providers.find(p => p.id.toString() === index));
    },
    showSettings(provider) {
      this.currentProvider = provider;
      this.settingsVisible = true;
    },
    handleProvidersUpdate(updatedProviders) {
      this.providers = updatedProviders;
    },
    showAddProviderDialog() {
      this.newProvider.name = '';
      this.addProviderVisible = true;
    },
    async handleAddProvider() {
      if (!this.newProvider.name.trim()) {
        this.$message.warning('请输入提供商名称');
        return;
      }

      try {
        const providerData = {
          name: this.newProvider.name.trim(),
          api_key: [{
            name: '',
            key: ''
          }],
          base_url: [{
            name: '',
            url: ''
          }]
        };

        const res = await modelProviderApi.saveProvider(providerData);
        const savedProvider = res.data;

        // 更新提供商列表
        this.providers.push(savedProvider);

        // 关闭弹框并清空表单
        this.addProviderVisible = false;
        this.newProvider.name = '';

        // 如果是第一个提供商，自动选中
        if (this.providers.length === 1) {
          this.handleSelect(savedProvider.id);
        }

        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败：' + (error.message || '未知错误'));
      }
    }
  }
};
</script>

<style scoped>
.provider-list {
  background: #fff;
  border-radius: 4px;
  padding: 8px;
}

.provider-menu {
  border-right: none;
}

.provider-menu :deep(.el-menu-item) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  line-height: 40px;
  padding: 0 12px;
}

.setting-btn {
  padding: 0;
  margin-left: 8px;
}

.setting-btn i {
  font-size: 16px;
}

.add-provider {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 12px;
  margin-top: 8px;
  color: #409EFF;
  font-size: 14px;
}

.add-provider:hover {
  background-color: #f5f7fa;
}

.add-provider i {
  margin-right: 8px;
  font-size: 16px;
}
</style> 