// api.js
import axios from "axios";
import router from "@/router";
import {Message} from "element-ui"; // 导入Message组件

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000,
});
// 专门给minio 服务器用的无鉴权的
const minioReq = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 10000,
});

// 添加请求拦截器
instance.interceptors.request.use(
    (config) => {
        // 在请求发送前，将保存在localStorage或Vuex中的token添加到请求头中
        const token = localStorage.getItem("token"); // 假设token保存在localStorage中
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200 && response.data.success) {
            return Promise.resolve(response);
        } else {
            if (response.data.message) {
                Message.error(response.data.message);
            }
            return Promise.reject(response);
        }
    },
    (error) => {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                case 422:
                case 403:
                    Message.error("Your login session has expired. Please log in again to continue.");
                    // 清除token
                    localStorage.removeItem("token");
                    router.push("/user/login");
                    break;
                case 404:
                    Message.error("The requested resource could not be found.");
                    router.push('/404')
                    break;
                default:
                    Message.error("Network request timed out.");
            }
            return Promise.reject(error.response);
        }
    }
);

// 封装 GET 请求方法，包含文件下载功能
export const get = async (url, params, isDownload = false) => {
    try {
        const config = {
            params,
            responseType: isDownload ? "blob" : "json", // 设置响应类型为 blob 或 json
        };

        const response = await instance.get(url, config);

        if (isDownload) {
            // 如果是下载文件，返回响应对象
            return response;
        } else {
            // 否则返回响应数据
            return response.data;
        }
    } catch (error) {
        return Promise.reject(error);
    }
};

export const minioPut = async (url, data, contentType = 'application/octet-stream') => {
    try {
        const response = await minioReq.put(url, data, {
            headers: {
                'Content-Type': contentType,
            },
        });
        return response;
    } catch (error) {
        console.error('PUT 请求失败:', error);
        return Promise.reject(error);
    }
};

// 封装 RESTful 风格的 GET 请求方法，包含文件下载功能
export const getRestful = async (url, resource, params, isDownload = false) => {
    try {
        url = resource ? `${url}/${resource}` : url;

        const config = {
            params,
            responseType: isDownload ? "blob" : "json", // 设置响应类型为 blob 或 json
        };

        const response = await instance.get(url, config);

        if (isDownload) {
            // 如果是下载文件，返回响应对象
            return response;
        } else {
            // 否则返回响应数据
            return response.data;
        }
    } catch (error) {
        return Promise.reject(error);
    }
};

// 封装 RESTful 风格的 POST 请求方法
export const postRestful = async (url, resource, data, isDownload = false) => {
    try {
        url = resource ? `${url}/${resource}` : url;
        const response = await instance.post(url, data);
        if (isDownload) {
            // 如果是下载文件，返回响应对象
            return response;
        } else {
            // 否则返回响应数据
            return response.data;
        }
    } catch (error) {
        return Promise.reject(error);
    }
};

// 封装 POST 请求方法
export const post = async (url, data, contentType = "application/json") => {
    try {
        const response = await instance.post(url, data, {
            headers: {
                "Content-Type": contentType, // 设置请求的Content-Type为application/json
            },
        });
        return response.data;
    } catch (error) {
        console.error("POST request error:", error);
        throw error; // 可以根据需要处理错误
    }
};
