<template>
  <div>
    <div class="btn-box">
      <el-button @click="add">添加</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%">
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row, scope.$index)">修改</el-button>
          <el-button type="text" @click="del(scope.row, scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="date" label="日期"> </el-table-column> -->
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="email" label="邮箱"></el-table-column>
    </el-table>
    <UserEditorDialog ref="userEditorDialog"/>
  </div>
</template>

<script>
import UserEditorDialog from "./UserEditorDialog.vue";
import {post} from '../../api'

export default {
  name: "UserConfig",
  components: {UserEditorDialog},
  data() {
    return {
      tableData: [],
      loading: true
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true
      post("/user/list", {}).then(res => {
        this.tableData = res.data
        this.loading = false
      })
    },
    // 添加弹窗打开
    add() {
      this.$refs.userEditorDialog.open();
    },
    // 编辑弹窗打开
    edit(row, index) {
      this.$refs.userEditorDialog.open(row, index);
    },
    // 删除
    del(row) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            post('/user/delete', row).then((res) => {
              if (res.success) {
                this.$message({
                  type: "success",
                  message: '删除成功！',
                });
                this.getTableData()
              }
            });
          }).catch(() => {
        this.$message.error('删除失败！');
      })
    },
  },
};
</script>

<style></style>