<template>
  <div class="bot-manager">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="机器人管理" name="1">
        <bot-list />
      </el-tab-pane>
      <el-tab-pane label="函数管理" name="2">
        <function-list />
      </el-tab-pane>
      <el-tab-pane label="模型管理" name="3">
        <model-manager />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import BotList from './components/BotList.vue';
import FunctionList from './components/FunctionList.vue';
import ModelManager from './components/ModelManager.vue';

export default {
  name: 'BotManager',
  components: {
    BotList,
    FunctionList,
    ModelManager
  },
  data() {
    return {
      activeTab: '1'
    };
  }
};
</script>

<style scoped>
.bot-manager {
  padding: 24px;
  background: #fff;
  min-height: 100%;
}
</style>