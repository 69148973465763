<template>
  <el-dialog
      :visible.sync="localVisible"
      :title="botData ? '编辑机器人' : '新增机器人'"
      width="600px"
      @close="handleCancel"
  >
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        label-width="100px"
    >
      <el-form-item label="机器人名称" prop="username">
        <el-input v-model="formData.username" placeholder="请输入机器人名称"/>
      </el-form-item>
      <el-form-item label="模型名称" prop="model_id">
        <el-select
            v-model="formData.model_id"
            filterable
            remote
            placeholder="请选择模型"
            :remote-method="get_model"
            :loading="selectLoading"
            style="width: 240px;">
          <el-option
              v-for="item in modelList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="系统提示词" prop="system_prompt">
        <el-input
            type="textarea"
            v-model="formData.system_prompt"
            :rows="4"
            placeholder="请输入系统提示词"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk" :loading="loading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {botApi} from '@/api/bot';
import {modelProviderApi} from '@/api/model_provider';

export default {
  name: 'BotDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    botData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      localVisible: this.visible, // 添加本地数据属性
      loading: false,
      selectLoading: false,
      modelList: [],
      formData: {
        user_id: '',
        username: '',
        model_id: '',
        system_prompt: '',
      },
      rules: {
        username: [{required: true, message: '请输入机器人名称', trigger: 'blur'}],
        model_id: [{required: true, message: '请选择模型', trigger: 'change'}],
        system_prompt: [{required: true, message: '请输入系统提示词', trigger: 'blur'}],
      }
    };
  },
  watch: {
    botData: {
      handler(val) {
        if (val) {
          this.formData = {...val};
        } else {
          this.formData = {
            username: '',
            model_id: '',
            system_prompt: '',
          };
        }
      },
      immediate: true
    },
    visible: {
      async handler(val) {
        this.localVisible = val; // 更新本地数据属性
        if (val) {
          await this.get_model();
        }
      },
      immediate: true
    }
  },
  methods: {
    async get_model(query) {
      this.selectLoading = true;
      try {
        const res = await modelProviderApi.getAllModels();
        this.modelList = res.data.map(item => ({
          id: item.id,
          name: item.name + " | " + item.provider_name,
        }));
        if (query) {
          this.modelList = this.modelList.filter(item => item.name.includes(query));
        }
      } catch (error) {
        this.$message.error('获取模型数据失败');
      } finally {
        this.selectLoading = false;
      }
    },
    handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            await botApi.saveBot(this.formData);
            this.$emit('success');
            this.$emit('update:visible', false);
            this.localVisible = false; // 更新本地数据属性
            this.$message.success('保存成功');
          } catch (error) {
            this.$message.error('保存失败');
          } finally {
            this.loading = false;
          }
        }
      });
    },
    handleCancel() {
      this.localVisible = false; // 更新本地数据属性
      this.$emit('update:visible', false);
    }
  }
};
</script> 