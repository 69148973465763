import {post, minioPut} from '@/api'

// 上传文件
async function uploadFile(bucket_name, object_name, file_data) {
    const data = {
        bucket_name: bucket_name,
        object_name: object_name
    }
    return post('/file/pre-signed-url', data).then(res => {
        return minioPut(res.data, file_data)
    })
}


export {
    uploadFile,
};