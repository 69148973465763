<template>
  <el-dialog
      :visible.sync="localVisible"
      title="用户分配"
      width="800px"
      @close="handleCancel"
  >
    <el-tabs v-model="activeTab">
      <el-tab-pane label="角色分配" name="1">
        <el-table
            :data="roleList"
            v-loading="loading"
            @selection-change="handleRoleSelectionChange"
            ref="roleTable"
        >
          <el-table-column
              type="selection"
              width="55"
          />
          <el-table-column
              prop="id"
              label="角色ID"
              width="100"
          />
          <el-table-column
              prop="name"
              label="角色名称"
              width="120"
          />
          <el-table-column
              prop="description"
              label="描述"
          />
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="用户分配" name="2">
        <el-table
            :data="userList"
            v-loading="loading"
            @selection-change="handleUserSelectionChange"
        >
          <el-table-column
              type="selection"
              width="55"
          />
          <el-table-column
              prop="id"
              label="用户ID"
              width="100"
          />
          <el-table-column
              prop="username"
              label="用户名"
              width="120"
          />
          <el-table-column
              prop="email"
              label="邮箱"
          />
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk" :loading="loading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {botApi} from '@/api/bot';

export default {
  name: 'UserAssignDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    botData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      activeTab: '1',
      localVisible: this.visible,
      loading: false,
      // 角色相关
      roleList: [
        {
          id: '1',
          name: '管理员',
          description: '系统管理员',
        },
        {
          id: '2',
          name: '普通用户',
          description: '普通用户',
        },
      ],
      // 用户相关
      userList: [
        {
          id: '1001',
          username: 'user1',
          email: 'user1@example.com',
        },
        {
          id: '1002',
          username: 'user2',
          email: 'user2@example.com',
        },
      ],
      selectedRoles: [],
      selectedUsers: []
    };
  },
  watch: {
    botData: {
      handler(val) {
        if (val?.use_to) {
          this.selectedRoles = val.use_to.role || [];
          this.selectedUsers = val.use_to.user || [];
          // 设置表格选中状态
          this.$nextTick(() => {
            this.setTableSelection();
          });
        }
      },
      immediate: true
    },
    visible: {
      async handler(val) {
        this.localVisible = val; // 更新本地数据属性
      },
      immediate: true
    }
  },
  methods: {
    setTableSelection() {
      const roleTable = this.$refs.roleTable;
      const userTable = this.$refs.userTable;
      if (roleTable) {
        this.roleList.forEach(row => {
          if (this.selectedRoles.includes(row.id)) {
            roleTable.toggleRowSelection(row, true);
          }
        });
      }
      if (userTable) {
        this.userList.forEach(row => {
          if (this.selectedUsers.includes(row.id)) {
            userTable.toggleRowSelection(row, true);
          }
        });
      }
    },
    handleRoleSelectionChange(selection) {
      this.selectedRoles = selection.map(item => item.id);
    },
    handleUserSelectionChange(selection) {
      this.selectedUsers = selection.map(item => item.id);
    },
    async handleOk() {
      this.loading = true;
      try {
        await botApi.assignUsers({
          bot_id: this.botData.id,
          selected_roles: this.selectedRoles,
          selected_users: this.selectedUsers
        });
        this.$emit('success');
        this.$emit('update:visible', false);
        this.$emit('reloadBotList');
        this.localVisible = false; // 更新本地数据属性
        this.$message.success('保存成功');
      } catch (error) {
        this.$message.error('保存失败');
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.localVisible = false; // 更新本地数据属性
      this.$emit('update:visible', false);
      this.$emit('reloadBotList');
    }
  }
};
</script>
