<template>
    <div>
        <el-form ref="form" :model="form" class="horizontal-form" label-position="top">
            <el-form-item label="Title">
                <el-input v-model="form.title" clearable width="30"></el-input>
            </el-form-item>
            <el-form-item label="categorys">
                <el-select v-model="form.category" clearable placeholder="请选择">
                    <el-option v-for="item in categorys" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSearch">Search</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getCacheList">Cache List</el-button>
            </el-form-item>
        </el-form>

        <div class="search-list" v-if="!cacheListVisible">
            Search List :
            <el-table v-loading="loading" :data="tableData" style="margin-top: 20px">
                <el-table-column label="Action">
                    <template slot-scope="scope">
                        <el-button v-if="!scope.row.file_name" @click="cache(scope.row)" size="small">cache</el-button>
                        <el-button v-if="scope.row.file_name" @click="preview(scope.row.file_name)"
                            size="small">preview</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="Title" prop="title"></el-table-column>
                <el-table-column label="Size" prop="file_size">
                    <template slot-scope="scope">
                        <span v-show="scope.row.file_size">{{ scope.row.file_size }} MB</span>
                    </template>
                </el-table-column>
                <el-table-column label="Category" prop="category"></el-table-column>
                <el-table-column label="Image" prop="image">
                    <template slot-scope="scope">
                        <img v-show="scope.row.img_link" :src="scope.row.img_link" width="150px">
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="cache-list" v-if="cacheListVisible">
            Cache List :
            <el-table :data="cacheList">
                <el-table-column label="Actions">
                    <template slot-scope="scope">
                        <el-button @click="preview(scope.row.file_name)" size="small">preview</el-button>
                        <el-button @click="del(scope.row.id)" size="small">delete</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="title" prop="title"></el-table-column>
                <el-table-column label="Size" prop="file_size">
                    <template slot-scope="scope">
                        <span v-show="scope.row.file_size">{{ scope.row.file_size }} MB</span>
                    </template>
                </el-table-column>
                <el-table-column label="Category" prop="category"></el-table-column>
                <el-table-column label="Image" prop="image">
                    <template slot-scope="scope">
                        <img v-show="scope.row.img_link" :src="scope.row.img_link" width="150px">
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { get, post, getRestful } from '../../api'

export default {
    name: "Magazine",
    data() {
        return {
            form: {
                title: '',
                category: '',
            },
            categorys: [],
            tableData: [],
            cacheList: [],
            cacheListVisible: false,
            loading: false,
            websitePrefix: 'https://taocoweb.top/static/magazine/'
        }
    },
    created() {
        this.getCategory();
    },
    methods: {
        getCategory() {
            get('/magazine/category').then(res => {
                this.categorys = res.data
            })
        },
        onSearch() {
            post('/magazine/list', this.form).then(res => {
                this.tableData = res.data
                this.cacheListVisible = false
            })
        },
        cache(maga) {
            post('/magazine/cache', maga).then(() => {
                this.$message.success('Start Caching!');
            })
        },
        preview(file_name) {
            window.open(this.websitePrefix + file_name, "_blank");
        },
        getCacheList() {
            get('/magazine/cache').then(res => {
                this.cacheList = res.data
                this.cacheListVisible = true
            })
        },
        del(id) {
            this.$confirm('Are you sure you want to delete the selected items?', 'Warning', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            })
                .then(() => {
                    getRestful('/magazine/cache/del', id).then(res => {
                        if (res.success) {
                            this.$message.success('Deleted!');
                            this.getCacheList()
                        }
                    })
                })
                .catch(() => {
                    this.$message.info('Delete canceled');
                });
        }
    }
}
</script>

<style></style>