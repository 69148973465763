export function generateRandomFileName(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomName = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomName += characters[randomIndex];
    }
    return randomName;
}

export function generateRandomId(length = 12) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters[randomIndex];
    }
    return randomId;
}

export async function getBlobFromUrl(url) {
    try {
        const response = await fetch(url);
        // 检查请求是否成功
        if (!response.ok) {
            return null;  // 请求失败，返回 null
        }
        const blob = await response.blob();
        return blob;
    } catch (error) {
        return null;  // 捕获错误并返回 null
    }
}