<template>
  <el-dialog
      :visible.sync="localVisible"
      title="函数分配"
      width="800px"
      @close="handleCancel"
  >
    <el-table
        :data="functionList"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        ref="table"
    >
      <el-table-column
          type="selection"
          width="55"
      />
      <el-table-column
          prop="id"
          label="ID"
          width="80"
      />
      <el-table-column
          prop="name"
          label="函数名称"
          width="120"
      />
      <el-table-column
          prop="description"
          label="描述"
      />
      <el-table-column
          prop="params_type"
          label="参数类型"
          width="100"
      />
      <el-table-column
          label="参数属性"
          width="100"
      >
        <template slot-scope="scope">
          <el-popover
              placement="right"
              width="400"
              trigger="hover"
          >
            <pre>{{ JSON.stringify(scope.row.params_properties, null, 2) }}</pre>
            <el-button slot="reference" type="text">查看参数</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          prop="base_tool"
          label="是否基础工具"
          width="100"
      >
        <template slot-scope="scope">
          {{ scope.row.base_tool ? '是' : '否' }}
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk" :loading="loading">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {botFuncApi} from '@/api/bot_func';
import {botApi} from '@/api/bot';

export default {
  name: 'FunctionAssignDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    botData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      functionList: [],
      selectedFunctions: [],
      localVisible: this.visible, // 添加本地数据属性
      isSettingSelection: false // 新增标志位
    };
  },
  watch: {
    botData: {
      handler(val) {
        if (val?.tools) {
          this.selectedFunctions = val.tools;
        }
      },
      immediate: true
    },
    visible: {
      async handler(val) {
        this.localVisible = val; // 更新本地数据属性
        if (val) {
          await this.fetchFunctionList();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchFunctionList() {
      this.loading = true;
      botFuncApi.getFunctions().then(res => {
        this.functionList = res.data || [];
        this.$nextTick(() => {
          this.setTableSelection();
        });
      }).catch(() => {
        this.$message.error('获取函数列表失败');
      }).finally(() => {
        this.loading = false;
      });
    },
    setTableSelection() {
      const table = this.$refs.table;
      if (table) {
        this.isSettingSelection = true; // 开始设置选中状态，禁用 handleSelectionChange
        this.functionList.forEach(row => {
          if (this.selectedFunctions.includes(row.id)) {
            table.toggleRowSelection(row, true);
          }
        });
        this.isSettingSelection = false; // 设置完成，启用 handleSelectionChange
      }
    },
    handleSelectionChange(selection) {
      if (!this.isSettingSelection) { // 只有当不是手动设置选中状态时才更新 selectedFunctions
        this.selectedFunctions = selection.map(item => item.id);
      }
    },
    async handleOk() {
      this.loading = true;
      try {
        await botApi.assignFunctions({
          bot_id: this.botData.id,
          selected_functions: this.selectedFunctions
        });
        this.$emit('success');
        this.$emit('reloadBotList');
        this.localVisible = false; // 更新本地数据属性
        this.$emit('update:visible', false); // 通知父组件
        this.$message.success('保存成功');
      } catch (error) {
        this.$message.error('保存失败');
      } finally {
        this.loading = false;
      }
    },
    handleCancel() {
      this.localVisible = false; // 更新本地数据属性
      this.functionList = []
      this.selectedFunctions = []
      this.isSettingSelection = false
      this.$emit('reloadBotList');
      this.$emit('update:visible', false); // 通知父组件
    }
  }
};
</script>