<template>
  <el-dialog
      :title="currentProvider ? `${currentProvider.name}设置` : '供应商设置'"
      :visible.sync="dialogVisible"
      width="600px"
  >
    <el-form :model="form" label-width="100px">
      <div class="section">
        <div class="section-header">
          <h4>基本信息</h4>
        </div>
        <el-form-item label="提供商名称" required>
          <el-input v-model="form.name" placeholder="请输入提供商名称"/>
        </el-form-item>
      </div>

      <el-divider></el-divider>

      <div class="section">
        <div class="section-header">
          <h4>API Keys</h4>
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="addApiKey"
          >添加 API Key
          </el-button>
        </div>
        <div v-for="(item, index) in form.api_key" :key="'key-'+index" class="input-item">
          <el-form-item :label="'名称 ' + (index + 1)" class="name-input">
            <el-input v-model="item.name" placeholder="请输入名称"/>
          </el-form-item>
          <el-form-item :label="'API Key ' + (index + 1)">
            <div class="input-with-delete">
              <el-input
                  v-model="item.key"
                  placeholder="请输入API Key"
              />
              <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="removeApiKey(index)"
                  v-if="form.api_key.length > 1"
              ></el-button>
            </div>
          </el-form-item>
        </div>
      </div>

      <el-divider></el-divider>

      <div class="section">
        <div class="section-header">
          <h4>Base URLs</h4>
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-plus"
              @click="addBaseUrl"
          >添加 Base URL
          </el-button>
        </div>
        <div v-for="(item, index) in form.base_url" :key="'url-'+index" class="input-item">
          <el-form-item :label="'名称 ' + (index + 1)" class="name-input">
            <el-input v-model="item.name" placeholder="请输入名称"/>
          </el-form-item>
          <el-form-item :label="'Base URL ' + (index + 1)">
            <div class="input-with-delete">
              <el-input
                  v-model="item.url"
                  placeholder="请输入Base URL"
              />
              <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="removeBaseUrl(index)"
                  v-if="form.base_url.length > 1"
              ></el-button>
            </div>
          </el-form-item>
        </div>
      </div>

      <el-divider></el-divider>

      <div class="danger-zone">
        <h4>危险操作</h4>
        <div class="danger-content">
          <span class="warning-text">删除后将无法恢复，请谨慎操作</span>
          <el-button
              type="danger"
              size="small"
              @click="confirmDelete"
          >删除此提供商
          </el-button>
        </div>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="handleSave">保存</el-button>
    </div>

    <!-- 删除确认对话框 -->
    <el-dialog
        title="确认删除"
        :visible.sync="deleteConfirmVisible"
        width="400px"
        append-to-body
    >
      <div class="delete-confirm-content">
        <i class="el-icon-warning"></i>
        <p>确定要删除 <strong>{{ currentProvider?.name }}</strong> 吗？</p>
        <p class="warning-text">此操作将永久删除该提供商及其所有配置，且无法恢复！</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteConfirmVisible = false">取消</el-button>
        <el-button type="danger" @click="handleDelete">确定删除</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import {modelProviderApi} from '@/api/model_provider';

export default {
  name: 'ProviderSettings',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    providers: {
      type: Array,
      default: () => []
    },
    currentProvider: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        name: this.currentProvider.name,
        api_key: this.currentProvider.api_key,
        base_url: this.currentProvider.base_url
      },
      deleteConfirmVisible: false
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  methods: {
    addApiKey() {
      this.form.api_key.push({
        name: '',
        key: ''
      });
    },
    removeApiKey(index) {
      this.form.api_key.splice(index, 1);
    },
    addBaseUrl() {
      this.form.base_url.push({
        name: '',
        url: ''
      });
    },
    removeBaseUrl(index) {
      this.form.base_url.splice(index, 1);
    },
    confirmDelete() {
      this.deleteConfirmVisible = true;
    },
    async handleDelete() {
      try {
        await modelProviderApi.deleteProvider(this.currentProvider.id);
        const updatedProviders = this.providers.filter(p => p.id !== this.currentProvider.id);
        this.$emit('update', updatedProviders);
        this.deleteConfirmVisible = false;
        this.dialogVisible = false;
        this.$message.success('删除成功');
      } catch (error) {
        this.$message.error('删除失败：' + (error.message || '未知错误'));
      }
    },
    async handleSave() {
      if (!this.form.name.trim()) {
        this.$message.error('请输入提供商名称');
        return;
      }

      try {
        const providerData = {
          id: this.currentProvider?.id,
          name: this.form.name,
          api_key: this.form.api_key.filter(key => key.name.trim() && key.key.trim()),
          base_url: this.form.base_url.filter(url => url.name.trim() && url.url.trim())
        };

        const res = await modelProviderApi.saveProvider(providerData);
        const savedProvider = res.data;

        const updatedProviders = this.currentProvider
            ? this.providers.map(p => p.id === this.currentProvider.id ? savedProvider : p)
            : [...this.providers, savedProvider];

        this.$emit('update', updatedProviders);
        this.dialogVisible = false;
        this.$message.success('保存成功');
      } catch (error) {
        this.$message.error('保存失败：' + (error.message || '未知错误'));
      }
    }
  },
  watch: {
    visible(val) {
      if (val && this.currentProvider) {
        // 加载当前供应商的设置
        this.form = {
          name: this.currentProvider.name,
          api_key: this.currentProvider.api_key || [{
            name: '',
            key: ''
          }],
          base_url: this.currentProvider.base_url || [{
            name: '',
            url: ''
          }]
        };
      } else {
        // 重置表单
        this.form = {
          name: '',
          api_key: [{
            name: '',
            key: ''
          }],
          base_url: [{
            name: '',
            url: ''
          }]
        };
      }
    }
  }
};
</script>

<style scoped>
.section {
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.section-header h4 {
  margin: 0;
  color: #606266;
}

.input-item {
  margin-bottom: 25px;
  padding: 15px;
  border: 1px solid #EBEEF5;
  border-radius: 4px;
}

.name-input {
  margin-bottom: 10px;
}

.input-with-delete {
  display: flex;
  align-items: center;
  gap: 10px;
}

.input-with-delete .el-input {
  flex: 1;
}

.el-divider {
  margin: 25px 0;
}

.danger-zone {
  border: 1px solid #f56c6c;
  border-radius: 4px;
  padding: 15px;
  margin-top: 20px;
}

.danger-zone h4 {
  color: #f56c6c;
  margin: 0 0 15px 0;
}

.danger-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warning-text {
  color: #909399;
  font-size: 14px;
}

.delete-confirm-content {
  text-align: center;
  padding: 20px 0;
}

.delete-confirm-content i {
  font-size: 48px;
  color: #e6a23c;
  margin-bottom: 15px;
}

.delete-confirm-content p {
  margin: 10px 0;
}

.delete-confirm-content .warning-text {
  color: #f56c6c;
  font-size: 12px;
}
</style> 