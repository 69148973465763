<template>
  <div class="function-list">
    <div class="operation-bar">
      <el-button type="primary" @click="showFunctionDialog()">
        <i class="el-icon-plus"></i> 新增函数
      </el-button>
    </div>

    <el-table :data="functionList" v-loading="loading" style="width: 100%">
      <el-table-column prop="id" label="ID" width="80"/>
      <el-table-column prop="name" label="函数名称" width="300"/>
      <el-table-column prop="description" label="描述"/>
      <el-table-column prop="prompt" label="提示词"/>
      <el-table-column label="参数属性" width="100">
        <template slot-scope="scope">
          <el-popover placement="right" width="400" trigger="hover">
            <pre>{{ JSON.stringify(scope.row.params_properties, null, 2) }}</pre>
            <el-button slot="reference" type="text">查看参数</el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="base_tool" label="基础工具" width="100">
        <template slot-scope="scope">
          {{ scope.row.base_tool ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="showFunctionDialog(scope.row)">编辑
          </el-button>
          <el-button type="text" class="danger-button" @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 函数编辑弹框 -->
    <el-dialog :visible.sync="dialogVisible" :title="currentFunction ? '编辑函数' : '新增函数'" width="800px">
      <el-form ref="form" :model="formData" :rules="rules" label-width="100px">
        <el-form-item label="函数名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入函数名称"/>
        </el-form-item>

        <el-form-item label="描述" prop="description">
          <el-input type="textarea" v-model="formData.description" :rows="2" placeholder="请输入函数描述"/>
        </el-form-item>
        <el-form-item label="提示词" prop="prompt">
          <el-input type="textarea" v-model="formData.prompt" :rows="2" placeholder="请输入提示词"/>
        </el-form-item>

        <el-form-item label="参数类型" prop="params_type">
          <el-input v-model="formData.params_type" placeholder="请输入参数类型" disabled/>
        </el-form-item>

        <el-form-item label="参数属性" prop="params_properties_str">
          <el-input type="textarea" v-model="formData.params_properties_str" :rows="6"
                    placeholder="请输入参数属性（JSON格式）"/>
        </el-form-item>

        <el-form-item label="必需参数" prop="params_required">
          <el-select v-model="formData.params_required" multiple filterable allow-create default-first-option
                     placeholder="请输入必需参数">
            <el-option v-for="item in formData.params_required" :key="item" :label="item" :value="item"/>
          </el-select>
        </el-form-item>

        <el-form-item label="命令" prop="command">
          <el-input v-model="formData.command" placeholder="请输入命令"/>
        </el-form-item>

        <el-form-item label="是否基础工具" prop="base_tool">
          <el-switch v-model="formData.base_tool"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleOk" :loading="dialogLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {botFuncApi} from '@/api/bot_func';

export default {
  name: 'FunctionList',
  data() {
    return {
      loading: false,
      functionList: [],
      dialogVisible: false,
      dialogLoading: false,
      currentFunction: null,
      formData: {
        name: '',
        description: '',
        prompt: '',
        params_type: 'object',
        params_properties_str: '',
        params_required: [],
        command: '',
        base_tool: false
      },
      rules: {
        name: [{required: true, message: '请输入函数名称', trigger: 'blur'}],
        description: [{required: true, message: '请输入函数描述', trigger: 'blur'}],
        params_type: [{required: true, message: '请输入参数类型', trigger: 'blur'}],
        params_properties_str: [{
          required: true,
          message: '请输入参数属性',
          trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error('请输入参数属性'));
              return;
            }
            try {
              JSON.parse(value);
              callback();
            } catch (error) {
              callback(new Error('请输入正确的JSON格式'));
            }
          },
          trigger: 'blur'
        }],
        command: [{required: true, message: '请输入命令', trigger: 'blur'}]
      }
    };
  },
  methods: {
    async fetchFunctionList() {
      this.loading = true;
      try {
        const res = await botFuncApi.getFunctions();
        this.functionList = res.data || [];
      } catch (error) {
        this.$message.error('获取函数列表失败');
      } finally {
        this.loading = false;
      }
    },
    showFunctionDialog(func = null) {
      this.currentFunction = func;
      if (func) {
        this.formData = {
          ...func,
          params_properties_str: JSON.stringify(func.params_properties, null, 2)
        };
      } else {
        this.formData = {
          name: '',
          description: '',
          prompt: '',
          params_type: 'object',
          params_properties_str: '',
          params_required: [],
          command: '',
          base_tool: false
        };
      }
      this.dialogVisible = true;
    },
    async handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const params_properties = JSON.parse(this.formData.params_properties_str);
            this.dialogLoading = true;
            // 这里应该调用保存API
            await botFuncApi.saveFunction({
              ...this.formData,
              params_properties
            });
            this.dialogVisible = false;
            this.$message.success('保存成功');
            await this.fetchFunctionList();
          } catch (error) {
            this.$message.error('参数属性JSON格式错误');
          } finally {
            this.dialogLoading = false;
          }
        }
      });
    },
    async handleDelete(func) {
      try {
        await this.$confirm('确认删除该函数吗？', '提示', {
          type: 'warning'
        });
        await botFuncApi.deleteFunction(func.id);
        this.$message.success('删除成功');
        await this.fetchFunctionList();
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('删除失败');
        }
      }
    }
  },
  mounted() {
    this.fetchFunctionList();
  }
};
</script>