<template>
  <div class="model-list">
    <div class="operation-bar">
      <el-button
          type="primary"
          @click="showModelDialog()"
          :disabled="!provider"
      >
        <i class="el-icon-plus"></i> 新增模型
      </el-button>
    </div>

    <el-table
        :data="models"
        v-loading="loading"
        style="width: 100%"
    >
      <el-table-column prop="type" label="模型类型" width="120">
        <template slot-scope="scope">
          <el-tag :type="getTypeTag(scope.row.type)">{{ scope.row.type }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          label="模型名称"
      />
      <el-table-column
          prop="model_id"
          label="模型ID"
      />
      <el-table-column
          prop="support_fc"
          label="是否支持FC"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.support_fc ? 'success' : 'info'">
            {{ scope.row.support_fc ? '是' : '否' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="only_stream"
          label="仅支持流式输出"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.only_stream ? 'warning' : 'info'">
            {{ scope.row.only_stream ? '是' : '否' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="is_active"
          label="状态"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.is_active ? 'success' : 'info'">
            {{ scope.row.is_active ? '启用' : '禁用' }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          fixed="right"
      >
        <template slot-scope="scope">
          <el-button
              type="text"
              @click="showModelDialog(scope.row)"
          >编辑
          </el-button>
          <el-button
              type="text"
              class="danger-button"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <model-form
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        :model="currentModel"
        :provider="provider"
        @fetchModels="fetchModels"
    />
  </div>
</template>

<script>
import {modelProviderApi} from '@/api/model_provider';
import ModelForm from './ModelForm.vue';


export default {
  name: 'ModelList',
  components: {
    ModelForm
  },
  props: {
    provider: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      models: [],
      loading: false,
      dialogVisible: false,
      currentModel: null
    };
  },
  watch: {
    provider: {
      immediate: true,
      handler(val) {
        if (val) {
          this.fetchModels();
        } else {
          this.models = [];
        }
      }
    }
  },
  methods: {
    getTypeTag(type) {
      const types = {
        '文本': 'success',
        '多模型': 'warning',
        '全模态': 'info'
      };
      return types[type] || 'info';
    },
    async fetchModels() {
      if (!this.provider) return;

      this.loading = true;
      try {
        const res = await modelProviderApi.getProviderModels(this.provider.id);
        this.models = res.data || [];
      } catch (error) {
        this.$message.error('获取模型列表失败');
      } finally {
        this.loading = false;
      }
    },
    showModelDialog(model) {
      this.currentModel = model;
      this.dialogVisible = true;
    },
    async handleDelete(model) {
      try {
        await this.$confirm('确认删除该模型吗？', '提示', {
          type: 'warning'
        });
        await modelProviderApi.deleteModel(model.id);
        this.$message.success('删除成功');
        await this.fetchModels();
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('删除失败');
        }
      }
    }
  }
};
</script>

<style scoped>
.model-list {
  height: 100%;
  padding: 0 20px;
}

.operation-bar {
  padding: 10px 0;
  margin-bottom: 10px;
}

.danger-button {
  color: #F56C6C;
}

.danger-button:hover {
  color: #f78989;
}
</style> 