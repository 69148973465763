import {get, post, getRestful} from '@/api';

// 机器人管理相关API
export const botApi = {
    // 获取机器人列表
    getBotList() {
        return get('/bot/list');
    },

    // 获取单个机器人详情
    getBot(botId) {
        return getRestful('/bot/get_bot', botId);
    },

    // 保存机器人（创建/编辑）
    saveBot(data) {
        return post('/bot/save', data);
    },

    // 删除机器人
    deleteBot(botId) {
        return getRestful('/bot/delete', botId);
    },

    // 分配用户
    assignUsers(data) {
        return post('/bot/assign_users', data);
    },

    // 分配函数
    assignFunctions(data) {
        return post('/bot/assign_functions', data);
    },
}; 