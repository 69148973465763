<template>
  <div class="bot-list">
    <div class="operation-bar">
      <el-button type="primary" @click="showBotDialog()">
        <i class="el-icon-plus"></i> 新增机器人
      </el-button>
    </div>

    <el-table
        :data="botList"
        v-loading="loading"
        style="width: 100%"
    >
      <el-table-column
          prop="id"
          label="ID"
          width="80"
      />
      <el-table-column
          prop="username"
          label="机器人名称"
          width="230"
      />
      <el-table-column
          prop="model_name"
          label="模型名称"
          width="230"
      />
      <el-table-column
          prop="system_prompt"
          label="系统提示词"
          show-overflow-tooltip
      />
      <el-table-column
          label="操作"
          width="300"
          fixed="right"
      >
        <template slot-scope="scope">
          <el-button
              type="text"
              @click="showBotDialog(scope.row)"
          >编辑
          </el-button>
          <el-button
              type="text"
              @click="showUserAssignDialog(scope.row)"
          >用户分配
          </el-button>
          <el-button
              type="text"
              @click="showFunctionAssignDialog(scope.row)"
          >函数分配
          </el-button>
          <el-button
              type="text"
              class="danger-button"
              @click="handleDelete(scope.row)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 机器人编辑弹框 -->
    <bot-dialog
        :visible.sync="botDialogVisible"
        :bot-data="currentBot"
        @success="handleSuccess"
    />

    <!-- 用户分配弹框 -->
    <user-assign-dialog
        :visible.sync="userAssignDialogVisible"
        :bot-data="currentBot"
        @success="handleSuccess"
        @reloadBotList="fetchBotList"
    />

    <!-- 函数分配弹框 -->
    <function-assign-dialog
        :visible.sync="functionAssignDialogVisible"
        :bot-data="currentBot"
        @success="handleSuccess"
        @reloadBotList="fetchBotList"
    />
  </div>
</template>

<script>
import BotDialog from './BotDialog.vue';
import UserAssignDialog from './UserAssignDialog.vue';
import FunctionAssignDialog from './FunctionAssignDialog.vue';
import {botApi} from '@/api/bot';

export default {
  name: 'BotList',
  components: {
    BotDialog,
    UserAssignDialog,
    FunctionAssignDialog
  },
  data() {
    return {
      loading: false,
      botList: [],
      botDialogVisible: false,
      userAssignDialogVisible: false,
      functionAssignDialogVisible: false,
      currentBot: null
    };
  },
  methods: {
    async fetchBotList() {
      this.loading = true;
      try {
        const res = await botApi.getBotList();
        this.botList = res.data || [];
      } catch (error) {
        console.error('获取机器人列表失败:', error);
        this.$message.error('获取机器人列表失败');
      } finally {
        this.loading = false;
      }
    },

    showBotDialog(bot = null) {
      this.currentBot = bot;
      this.botDialogVisible = true;
    },

    showUserAssignDialog(bot) {
      this.currentBot = bot;
      this.userAssignDialogVisible = true;
    },

    showFunctionAssignDialog(bot) {
      this.currentBot = bot;
      this.functionAssignDialogVisible = true;
    },

    async handleSuccess() {
      await this.fetchBotList();
      this.$message.success('操作成功');
    },

    async handleDelete(bot) {
      try {
        await this.$confirm('确认删除该机器人吗？', '提示', {
          type: 'warning'
        });
        await botApi.deleteBot(bot.id);
        this.$message.success('删除成功');
        await this.fetchBotList();
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error('删除失败');
        }
      }
    }
  },
  mounted() {
    this.fetchBotList();
  }
};
</script>