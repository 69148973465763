<template>
  <div class="login-container" v-loading="createLoading">
    <el-form ref="form" :model="form" class="login-form" :rules="rules" v-if="!checkEmail">
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input type="password" v-model="form.confirmPassword"></el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onRegister">注册</el-button>
        <router-link to="/user/login" style="margin-left: 10px;">已有账号，立刻登录</router-link>
      </el-form-item>
    </el-form>

    <el-form ref="form" :model="form" class="login-form" v-loading="checkEmailLoading"
             :rules="rules" v-else>
      <el-form-item label="Code" prop="code">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onCheckEmail">验证</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import md5 from 'js-md5';
import {post} from '@/api.js';

export default {
  name: "Register",
  data() {
    return {
      checkEmail: false,
      createLoading: false,
      checkEmailLoading: false,
      form: {
        email: '',
        password: '',
        confirmPassword: '',
        username: '',
        code: '',
      },
      rules: {
        email: [
          {required: true, message: '请输入邮箱地址', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 6, message: '密码长度至少为6个字符', trigger: 'blur'}
        ],
        confirmPassword: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
          {validator: (rule, value, callback) => this.validateConfirmPassword(rule, value, callback), trigger: 'blur'}
        ],
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ]
      },
      salt: 'TaocoWebInvestBlog'
    }
  },
  methods: {
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
    onRegister() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.createLoading = true
          const payload = {
            'email': this.form.email,
            'password': md5(this.salt + this.form.password),
            'username': this.form.username
          }
          post('/user/register', payload).then(() => {
            this.checkEmail = true
          }).finally(() => {
            this.createLoading = false
          });
        }
      });
    },
    onCheckEmail() {
      this.checkEmailLoading = true
      const payload = {
        'email': this.form.email,
        'code': this.form.code
      }
      post('/user/check-email', payload).then(() => {
        this.$router.push('/user/login')
      }).finally(() => {
        this.checkEmailLoading = false
      });
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.login-form {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>