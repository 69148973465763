import {get, post, getRestful} from '@/api';

// 函数管理相关API
export const botFuncApi = {

    // 获取所有可用函数列表
    getFunctions() {
        return get('/func/get_functions');
    },

    // 保存函数
    saveFunction(data) {
        return post('/func/save_function', data);
    },

    // 删除函数
    deleteFunction(functionId) {
        return getRestful('/func/delete_function', functionId);
    }
}; 