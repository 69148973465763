import VueRouter from "vue-router";
import {get} from "../api";

import Dashboard from "../user/Dashboard.vue";
import NewsList from "../user/dashboard/NewsList.vue";
import Youtube from "../user/dashboard/Youtube.vue";
import VoaLearnEnglish from "../user/VoaLearnEnglish.vue";
import Music from "../user/Music.vue";
import Stock from "../user/dashboard/Stock.vue";
import Reminder from "../user/dashboard/Reminder.vue";
import Chat from "../user/dashboard/Chat.vue";
import BlogList from "../user/dashboard/BlogList.vue";
import Vocabulary from "../user/dashboard/Vocabulary.vue";
import Login from "../user/Login.vue";
import NewsDetail from "../user/NewsDetail.vue";
import BlogEditor from "../user/dashboard/BlogEditor.vue";
import Schedule from "../user/dashboard/Schedule.vue";
import Tt from "../user/dashboard/Tt.vue";
import UserConfig from "../user/dashboard/UserConfig.vue";
import Magazine from "../user/dashboard/Magazine.vue";
import BookDetail from "../user/dashboard/BookDetail.vue";
import ImageGallery from "../user/dashboard/ImageGallery.vue";
import MusicList from "../user/dashboard/MusicList.vue";
import VoaDetail from "../user/VoaDetail.vue";
import Register from "../user/Register.vue";
import Home from "../frontend/Home.vue";
import NotFound from "../frontend/NotFound.vue";
import BlogDetail from "../frontend/BlogDetail.vue";
import BotManager from "../user/dashboard/BotManager.vue";

const router = new VueRouter({
    mode: "history",
    //一堆路由
    routes: [
        {
            path: "/",
            component: Home,
            meta: {title: "Taocoweb | Home", keepAlive: true},
        },
        {
            path: "/blog/:uri",
            component: BlogDetail,
            meta: {keepAlive: true}
        },
        {
            path: "/404",
            component: NotFound,
            meta: {keepAlive: true}
        },
        {
            path: "/user/login",
            component: Login,
            meta: {title: "Login"},
        },
        {
            path: "/register",
            component: Register,
            meta: {title: "Register"},
        },
        {
            path: "/news/:newsId",
            component: NewsDetail,
            meta: {keepAlive: true}
        },
        {
            path: "/voaLearnEnglish",
            component: VoaLearnEnglish,
            meta: {title: "VoaLearnEnglish", keepAlive: true},
        },
        {
            path: "/music",
            component: Music,
            meta: {title: "Music", keepAlive: true},
        },
        {
            path: "/voa/:voaId",
            component: VoaDetail,
            meta: {keepAlive: true}
        },
        {
            path: "/user/dashboard",
            component: Dashboard,
            children: [
                {
                    path: "", // 空路径表示默认子路由
                    component: BlogEditor,
                    meta: {title: "BlogEditor", keepAlive: true},
                },
                {
                    path: "tt",
                    component: Tt,
                    meta: {title: "Tt"},
                    name: "dashboard-tt",
                },
                {
                    path: "book-detail",
                    component: BookDetail,
                    meta: {title: "BookDetail"},
                    name: "dashboard-book-detail",
                },
                {
                    path: "schedule",
                    component: Schedule,
                    meta: {title: "Schedule", keepAlive: true},
                    name: "dashboard-schedule",
                },
                {
                    path: "botManager",
                    component: BotManager,
                    meta: {title: "BotManager", keepAlive: true},
                    name: "dashboard-botManager",
                },
                {
                    path: "vocabulary/:date",
                    component: Vocabulary,
                    meta: {title: "Vocabulary", keepAlive: true},
                    name: "dashboard-vocabulary",
                },
                {
                    path: "vocabulary",
                    component: Vocabulary,
                    meta: {title: "Vocabulary", keepAlive: true},
                    name: "dashboard-vocabulary",
                },
                {
                    name: "dashboard-blog-editor",
                    path: "blog-editor",
                    component: BlogEditor,
                    meta: {title: "BlogEditor", keepAlive: true},
                },
                {
                    path: "news-list",
                    component: NewsList,
                    meta: {title: "NewsList", keepAlive: true},
                    name: "dashboard-news-list",
                },
                {
                    path: "youtube",
                    component: Youtube,
                    meta: {title: "Youtube", keepAlive: true},
                    name: "dashboard-youtube",
                },
                {
                    path: "stock",
                    component: Stock,
                    meta: {title: "Stock"},
                    name: "dashboard-stock",
                },
                {
                    path: "reminder",
                    component: Reminder,
                    meta: {title: "Reminder", keepAlive: true},
                    name: "dashboard-reminder",
                },
                {
                    path: "chat",
                    component: Chat,
                    meta: {title: "Chat"},
                    name: "dashboard-chat",
                },
                {
                    path: "blog-list",
                    component: BlogList,
                    meta: {title: "BlogList", keepAlive: true},
                    name: "dashboard-blog-list",
                },
                {
                    path: "user-config",
                    component: UserConfig,
                    meta: {title: "UserConfig", keepAlive: true},
                    name: "dashboard-user-config",
                },
                {
                    path: "image-gallery",
                    component: ImageGallery,
                    meta: {title: "ImageGallery", keepAlive: true},
                    name: "dashboard-image-gallery",
                },
                {
                    path: "music-list/:id",
                    component: MusicList,
                    meta: {title: "MusicList", keepAlive: true},
                    name: "dashboard-music-list",
                },
                {
                    path: "music-list",
                    component: MusicList,
                    meta: {title: "MusicList", keepAlive: true},
                    name: "dashboard-music-list",
                },
                {
                    path: "magazine",
                    component: Magazine,
                    meta: {title: "Magazine", keepAlive: true},
                    name: "dashboard-magazine",
                },
            ],
        },
    ],
});

// 全局前置守卫
router.beforeEach(async (to, from, next) => {
    // 设置标签页title
    document.title = to.meta.title || "Loading...";
    // 如果要去登陆页
    if (to.path == "/user/login") {
        //保存当前路由
        localStorage.setItem("preRoute", router.currentRoute.fullPath);
    }
    // 如果是以 '/user/' 开头的路由，执行验证操作
    if (to.path.startsWith("/user/") && to.path != "/user/login") {
        try {
            // 调用接口验证登录状态，异常捕获和处理由axios响应处理器处理，这里不做处理
            await get("/user/check-login");
        } catch (error) {
            // 验证失败后保存目标页，仅限跳转目标页失败使用，登陆跳转之后需删除 // 传递param只能是name
            localStorage.setItem("postRoute", to.name);
            // 目标页面可能携带数据，只考虑Param，因为restful和query已经在fullpath中了
            localStorage.setItem("postRouteParams", JSON.stringify(to.params));
            // 重定向到登录页
            return next('/user/login');
        }
    }
    next();
});

export default router;
