<template>
  <el-dialog :title="model ? '编辑模型' : '新增模型'" :visible.sync="dialogVisible" width="500px">
    <el-form :model="form" label-width="100px" :rules="rules" ref="form" :loading="dialogLoading">
      <el-form-item label="模型类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择模型类型">
          <el-option label="文本" value="文本"/>
          <el-option label="多模型" value="多模型"/>
          <el-option label="全模态" value="全模态"/>
        </el-select>
      </el-form-item>
      <el-form-item label="模型名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入模型名称"/>
      </el-form-item>
      <el-form-item label="模型ID" prop="model_id">
        <el-input v-model="form.model_id" placeholder="请输入模型ID"/>
      </el-form-item>
      <el-form-item label="API Key" prop="api_key">
        <el-select v-model="form.api_key" placeholder="请选择API Key">
          <el-option v-for="key in provider?.api_key || []" :key="key.key" :label="key.name" :value="key.key"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Base URL" prop="base_url">
        <el-select v-model="form.base_url" placeholder="请选择Base URL">
          <el-option v-for="url in provider?.base_url || []" :key="url.url" :label="url.name" :value="url.url"/>
        </el-select>
      </el-form-item>
      <el-form-item label="支持函数调用" prop="support_fc">
        <el-switch v-model="form.support_fc"/>
      </el-form-item>
      <el-form-item label="仅流式输出" prop="only_stream">
        <el-switch v-model="form.only_stream"/>
      </el-form-item>
      <el-form-item label="状态" prop="is_active">
        <el-switch v-model="form.is_active"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="handleOk">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {modelProviderApi} from "@/api/model_provider";

export default {
  name: 'ModelForm',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: null
    },
    provider: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        id: null,
        type: '',
        name: '',
        model_id: '',
        api_key: '',
        base_url: '',
        support_fc: false,
        only_stream: false,
        is_active: true
      },
      dialogLoading: false,
      rules: {
        type: [{required: true, message: '请选择模型类型', trigger: 'change'}],
        name: [{required: true, message: '请输入模型名称', trigger: 'blur'}],
        model_id: [{required: true, message: '请输入模型ID', trigger: 'blur'}],
        api_key: [{required: true, message: '请选择API Key', trigger: 'change'}],
        base_url: [{required: true, message: '请选择Base URL', trigger: 'change'}]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  watch: {
    model: {
      immediate: true,
      handler(val) {
        if (val) {
          // 编辑模式：初始化表单数据
          this.form = {
            id: val.id || null,
            type: val.type || '',
            name: val.name || '',
            model_id: val.model_id || '',
            api_key: val.api_key || '',
            base_url: val.base_url || '',
            support_fc: val.support_fc || false,
            only_stream: val.only_stream || false,
            is_active: val.is_active
          };
        } else {
          // 新增模式：重置表单
          this.form = {
            id: null,
            type: '',
            name: '',
            model_id: '',
            api_key: '',
            base_url: '',
            support_fc: false,
            only_stream: false,
            is_active: true
          };
        }
      }
    }
  },
  methods: {
    async handleOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.dialogLoading = true;
          try {
            await modelProviderApi.saveModel({
              ...this.form,
              provider_id: this.provider.id
            });
            this.dialogVisible = false;
            this.$message.success('保存成功');
            await this.$emit('fetchModels');
          } finally {
            this.dialogLoading = false;
          }
        }
      });
    }
  }
};
</script>